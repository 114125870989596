import React from "react"

import SEO from "../components/seo"

const title = { is: "Villa kom upp", en: "Error" }

const description = {
  is: "Síðan sem þú ert að reyna að finna er ekki til",
  en: "The page that you are trying to find does not exist",
}

const NotFoundPage = ({ data, location }) => {
  return (
    <>
      <SEO title="Síða ekki til" />
      <section className=" absolute w-full inset-0 flex">
        <div className="relative flex justify-center items-center w-full">
          <div className="text-center">
            <h1 className=" text-xl xl:text-3xl text-neutrals-1000">
              {title.is}
            </h1>
            <p className="text-lg xl:text-2xl text-neutrals-700">
              {description.is}
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default NotFoundPage
